/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

.react-date-picker__wrapper {
  padding: 0.5rem;
  border-radius: 6px;
  margin-top: 0.2rem;
  border-color: #e7e7e9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.stream {
  background: linear-gradient(rgba(14, 13, 13, 0.45), #18181b);
}

.recipe-card figcaption {
  bottom: -6em;
  transition: all 0.3s ease-in;
}
.recipe-card:hover figcaption {
  bottom: 0;
}
/* .h-100 {
  height: 32rem;
} */

.scrollbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-color: transparent transparent;
}

.scrollbar ::-moz-scrollbar {
  display: none;
  scrollbar-color: transparent transparent;
}

/* .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 2px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  } */
#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  direction: rtl;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ff2323;
}

.float-circle {
  position: absolute;
  top: 50%;
  left: 75%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 50px;
  height: 50px;
  -webkit-transition: transform 0.5s, background-color 0.5s, opacity 0.2s;
  -moz-transition: transform 0.5s, background-color 0.5s, opacity 0.2s;
  -ms-transition: transform 0.5s, background-color 0.5s, opacity 0.2s;
  -o-transition: transform 0.5s, background-color 0.5s, opacity 0.2s;
  transition: transform 0.5s, background-color 0.5s, opacity 0.2s;
  background-color: #fff;
  border-radius: 50%;
}
.float-circle.open {
  cursor: pointer;
}
.float-circle.open#flickr {
  background-color: #1c61bb;
  -moz-transform: translate3d(-175%, -150%, 0);
  -o-transform: translate3d(-175%, -150%, 0);
  -ms-transform: translate3d(-175%, -150%, 0);
  -webkit-transform: translate3d(-175%, -150%, 0);
  transform: translate3d(-175%, -150%, 0);
}
.float-circle.open#share {
  background-color: #ff0000;
  -moz-transform: translate3d(-197%, -33%, 0);
  -o-transform: translate3d(-197%, -33%, 0);
  -ms-transform: translate3d(-197%, -33%, 0);
  -webkit-transform: translate3d(-197%, -33%, 0);
  transform: translate3d(-197%, -33%, 0);
}
.float-circle.open#youtube {
  background-color: #ff0808;
  -moz-transform: translate3d(-50%, -200%, 0);
  -o-transform: translate3d(-50%, -200%, 0);
  -ms-transform: translate3d(-50%, -200%, 0);
  -webkit-transform: translate3d(-50%, -200%, 0);
  transform: translate3d(-50%, -200%, 0);
}
.float-circle.open#dropbox {
  background-color: #2db61b;
  -moz-transform: translate3d(75%, -150%, 0);
  -o-transform: translate3d(75%, -150%, 0);
  -ms-transform: translate3d(75%, -150%, 0);
  -webkit-transform: translate3d(75%, -150%, 0);
  transform: translate3d(75%, -150%, 0);
}
.float-circle.open#twitter {
  background-color: #f52a2a;
  -moz-transform: translate3d(103%, -31%, 0);
  -o-transform: translate3d(103%, -31%, 0);
  -ms-transform: translate3d(103%, -31%, 0);
  -webkit-transform: translate3d(103%, -31%, 0);
  transform: translate3d(103%, -31%, 0);
}
.float-circle.open:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.float-circle.open:active {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.float-circle.no-animation {
  animation: none;
}

.main-button {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 81px;
  height: 81px;
  background-color: red;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.share {
  position: absolute;
  top: 50%;
  left: 75%;
  -moz-transform: translate3d(-50%, -50%, 0);
  -o-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.share-icon {
  color: #fff;
  font-size: 25px;
}

.social {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.social-icon {
  margin: 23px 0 0 -65px;
  font-size: 45px;
}

.react-player {
  border-radius: 1.5rem;
}

/* communities customgrid grid*/

.cgrid {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: 60%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

@media (min-width: 320px) and (max-width: 640px) {
  .cgrid {
    grid-template-columns: 1fr;
  }
}

.css-1s2u09g-control {
  border: none !important;
  background: #f4f4f5 !important;
  outline: none !important;
  border-radius: 0.5rem;
}


.hide-scroll-bar {
  /* -ms-overflow-style: none; */
  scrollbar-width: 5px;
}
.hide-scroll-bar::-webkit-scrollbar {
  width: 5px;
 
    border-radius: 5px;
}

.templates{
  width: 60rem;
  overflow: auto;
}

/* video revisions */


/* video revisions scrollbar  */ 

/* width */
.video-revisions::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.video-revisions::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */

.video-revisions::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.video-revisions::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



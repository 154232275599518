.generateBriefLoading, .generateBriefResult {
  opacity: 0;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* Define final state when component is loaded */
.component-loaded .generateBriefLoading, .component-loaded .generateBriefResult {
  opacity: 1;
}

/* Slide in from right animation */
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.slide-in {
  animation: slide-in 0.5s forwards;
  transform: translateX(100%); /* Start off-screen */
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

.scrollable-select .react-select__menu-list {
  max-height: 200px; /* Set the height as per your requirement */
  overflow-y: auto;
}
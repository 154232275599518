.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.left-arrow {
  background-image: url(./Assets/left-arrow.png);
  background-size: 100%;
  background-position: center;
  cursor: pointer;
  margin: 0 1px;
  margin-top: 14rem;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}

.right-arrow {
  background-image: url(./Assets/right-arrow.png);
  background-size: 100%;
  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
  margin: 0 1px;
  margin-top: 14rem;
  width: 40px;
  height: 40px;
}
.draft-video {
  min-width: 100%;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.submit-note {
  width: fit-content;
  float: right;
}
.creator {
  background-image: url('./Assets/creator.png');
  background-position: left;
  background-repeat: no-repeat;
  background-position-x: 11px;
  padding-left: 50px;
}
.creator:hover {
  background-image: url('./Assets/creator_hover.png');
}
.asset {
  background-image: url('./Assets/avatar.png');
  background-position: left;
  background-repeat: no-repeat;
  background-position-x: 19px;
  padding-left: 55px;
}
.step1 {
  background-image: url('./Assets/step1.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.credit-count {
  width: fit-content;
}

.asset:hover {
  background-image: url('./Assets/assets_hover.png');
}
.asset:focus {
  background-image: url('./Assets/assets_hover.png');
}
.billing {
  background-image: url('./Assets/billing_1.png');
  background-position: left;
  background-size: 23px;
  background-repeat: no-repeat;
  background-position-x: 23px;
  padding-left: 55px;
}

.billing:hover {
  background-image: url('./Assets/billing_1_hover.png');
}
.billing:focus {
  background-image: url('./Assets/billing_1_hover.png');
}

.recipe {
  background-image: url('./Assets/recipes.png');
  background-position: left;
  background-repeat: no-repeat;
  background-position-x: 13px;
  padding-left: 55px;
}

.recipe {
  background-image: url('./Assets/recipes.png');
  background-position: left;
  background-repeat: no-repeat;
  background-position-x: 13px;
  padding-left: 55px;
}
.recipe:hover {
  background-image: url('./Assets/recipes_hover.png');
}
.addSocial {
  background-image: url('./Assets/socialMedia.png');
  background-repeat: no-repeat;
  background-size: 90%;
}

.recipe:focus {
  background-image: url('./Assets/recipes_hover.png');
}
.event {
  background-image: url('./Assets/event.png');
  background-position: left;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position-x: 15px;
  padding-left: 50px;
}
.event:hover {
  background-image: url('./Assets/events_hover.png');
}
.event:focus {
  background-image: url('./Assets/events_hover.png');
}
.offer {
  background-image: url('./Assets/offers.png');
  background-position: left;
  background-repeat: no-repeat;
  background-position-x: 19px;
  padding-left: 55px;
}
.offer:hover {
  background-image: url('./Assets/offers_hover.png');
  background-position-x: 13px;
}
.offer:focus {
  background-image: url('./Assets/offers_hover.png');
  background-position-x: 13px;
}
.Instagram {
  background-image: url('./Assets/instagram.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.instagram {
  background-image: url('./Assets/instagram.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Facebook {
  background-image: url('./Assets/facebook.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Twitter {
  background-image: url('./Assets/twitter.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Whatsapp {
  background-image: url('./Assets/whatsapp.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Youtube {
  background-image: url('./Assets/youtube-shorts.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.youtube {
  background-image: url('./Assets/youtube-shorts.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.TikTok {
  background-image: url('./Assets/tiktok-square.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.tiktok {
  background-image: url('./Assets/tiktok-square.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.social-tw {
  background-image: url('./Assets/offers.png');
  background-position: center;
  background-repeat: no-repeat;
  padding: 5px;
  height: 40px;
  width: 40px;
}
.social-link {
  background-image: url('./Assets/offers.png');
  background-position: center;
  background-repeat: no-repeat;
  padding: 5px;
  height: 40px;
  width: 40px;
}

.form-control {
  margin-right: 20%;
  outline: none;
  background-color: rgba(244, 244, 245);
  border-radius: 0.5rem;
  padding: 10px;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.std-button {
  display: block;
  width: auto;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 10px 20px 10px 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border: 0px solid #000;
  border-radius: 20px;
  background-color: #ff2642;
  background-image: url(./Assets/Vector-4.svg);
  background-position: 20px 50%;
  background-size: auto 15px;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 3px 0 #afafaf;
  -webkit-transition: padding 500ms cubic-bezier(0.23, 1, 0.32, 1),
    background-position 500ms cubic-bezier(0.23, 1, 0.32, 1), color 200ms ease, opacity 200ms ease,
    -webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: padding 500ms cubic-bezier(0.23, 1, 0.32, 1),
    background-position 500ms cubic-bezier(0.23, 1, 0.32, 1), color 200ms ease, opacity 200ms ease,
    -webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: padding 500ms cubic-bezier(0.23, 1, 0.32, 1),
    background-position 500ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 500ms cubic-bezier(0.23, 1, 0.32, 1), color 200ms ease, opacity 200ms ease;
  transition: padding 500ms cubic-bezier(0.23, 1, 0.32, 1),
    background-position 500ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 500ms cubic-bezier(0.23, 1, 0.32, 1), color 200ms ease, opacity 200ms ease,
    -webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
  font-family: Montserrat, sans-serif;
  color: #fff;
  line-height: 1.6em;
  font-weight: 500;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.std-button:hover {
  padding-left: 50px;
  background-position: 30px 50%;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

/* otp input */
.otp-wrap {
  width: 100% !important;
}
.otp-wrap input {
  width: 53px !important;
  height: 74px !important;
  background: #f6f6f6;
  border-radius: 5px;
  border: none !important;
}
.otp-wrap input:not(:last-child) {
  margin-right: 31px;
}

/* phone input */
.phone-input.react-tel-input .form-control {
  background: #f6f6f6;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 40px;
}
.phone-input.react-tel-input .flag-dropdown {
  border: none;
}

/*custom modal*/
.customWideModal.react-responsive-modal-modal {
  width: 80%;
  border-radius: 15px;
  overflow: visible;
  z-index:0;
  min-height: 85vh;
  max-width: none; /* Override max-width */
}

@media (max-width: 768px) {
  .customWideModal.react-responsive-modal-modal::before {
    content: none; /* Remove the ::before pseudo-element */
  }
}

.customModal.react-responsive-modal-closeButton {
  top: 30px;
  right: 80px;
}
.customModal.react-responsive-modal-modal {
  padding: 36px 62px 45px;
  border-radius: 15px;
  width: 660px;
}

.customModalTwo.react-responsive-modal-modal {
  padding: 36px 62px 45px;
  border-radius: 15px;
  width: 60%;
}
.customModalTwo.react-responsive-modal-closeButton {
  top: 30px;
  right: 80px;
}
.customModalTwo.react-responsive-modal-overlay {
  background: rgba(255, 255, 255, 0.7) !important;
}


.customModalBlack.react-responsive-modal-modal {
  /* background: #454342; */
  padding: 38px 28px 45px;
}
.react-responsive-modal-closeButton svg {
  fill: #969;
}

.customModal.react-responsive-modal-overlay {
  background: rgba(255, 255, 255, 0.7) !important;
}

@media screen and (max-width: 768px) {
  .customModal.react-responsive-modal-modal {
    width: 90%;
    padding: 20px 34px 28px;
    margin: 0;
    border-radius: 20px;
  }
  .customModalTwo.react-responsive-modal-modal {
    width: 90%;
    padding: 20px 34px 28px;
    margin: 0;
    border-radius: 20px;
  }
  .customModal .react-responsive-modal-closeButton {
    right: 20px;
  }
}

/* Photo Upload */
.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0;
}

/*video page*/
.product-page {
  height: 124px;
}
.video-player {
  height: calc(100% - 154px);
}
@media screen and (max-width: 768px) {
  .product-page {
    height: 96px;
  }
  .video-player {
    height: calc(100% - 104px);
  }
}

.dashboardIconSize {
  display: 'inline';
  width: '20px';
  height: '20px';
}

.switch-link {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-link input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-link {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-link:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider-link {
  background-color: #2196f3;
}

input:focus + .slider-link {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider-link:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider-link.round {
  border-radius: 34px;
}

.slider-link.round:before {
  border-radius: 50%;
}
a {
  text-decoration: solid;
}

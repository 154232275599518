.dropdown-container {
  position: relative;
}

.dropdown-button {
  display: none;
}

.dropdown-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, max-height 0.5s ease;
  z-index: 1;
}

@media (max-width: 900px) {
  .dropdown-button {
    display: block;
  }

  .dropdown-content.open {
    opacity: 1;
    max-height: 500px; /* adjust this value to fit your content */
  }
}